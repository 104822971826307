:root {
    --font-heading: 'Space Grotesk', sans-serif;
    --font-body: 'Poppins', sans-serif;
}

body {
    font-family: var(--font-body);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-heading);
    letter-spacing: -0.02em;
}

.App {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem 1rem;
    background-color: #f8fafc;
}

.container {
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 10px 15px rgba(0, 0, 0, 0.03);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

header {
    padding: 2.5rem 2rem;
    text-align: center;
    border-bottom: 1px solid #e2e8f0;
    background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
    color: white;
}

h1 {
    font-size: 2.75rem;
    color: #ffffff;
    margin-bottom: 0.5rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subtitle {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
    letter-spacing: 0.02em;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
    background-color: #f8fafc;
    padding: 0 1rem;
}

.tabs button {
    flex: 1;
    padding: 1.25rem 1rem;
    background: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: var(--font-heading);
    position: relative;
    overflow: hidden;
}

.tabs button:hover {
    color: #0f172a;
}

.tabs button.active {
    color: #0369a1;
    font-weight: 600;
}

.tabs button.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #0ea5e9 0%, #3b82f6 100%);
}

main {
    flex: 1;
    padding: 2rem;
}

.section-content h2 {
    font-size: 1.75rem;
    color: #0f172a;
    margin-bottom: 0.75rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.5rem;
}

.section-content h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #0ea5e9 0%, #3b82f6 100%);
    border-radius: 3px;
}

.section-description {
    color: #64748b;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
}

.tools-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.tool-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    border: 1px solid #e2e8f0;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tool-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 10px 15px rgba(0, 0, 0, 0.03);
}

.tool-card h3 {
    font-size: 1.3rem;
    color: #0f172a;
    margin-bottom: 1rem;
    font-weight: 600;
}

.tool-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.input-group,
.select-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.input-group label,
.select-group label {
    font-size: 0.9rem;
    color: #64748b;
    font-weight: 500;
}

.select-container {
    position: relative;
    width: 100%;
}

.unit-conversion-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.unit-conversion-container select {
    flex: 1;
    min-width: 0;
}

.tool-inputs input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #cbd5e1;
    border-radius: 6px;
    font-size: 0.9rem;
}

.tool-inputs select {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 1rem;
    flex: 1;
}

.arrow {
    font-size: 1.2rem;
    color: #64748b;
}

.tool-result {
    background-color: #f8fafc;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-size: 1rem;
    transition: opacity 0.3s ease;
}

.tool-result.calculating {
    opacity: 0.6;
}

.result-label {
    color: #64748b;
    font-weight: 500;
    margin-right: 0.5rem;
}

.result-value {
    font-weight: 600;
    color: #0369a1;
    font-size: 1.1rem;
    margin-right: 0.25rem;
}

.result-unit {
    color: #64748b;
    font-weight: 500;
}

.tool-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.guid-result {
    background-color: #f8fafc;
    padding: 1rem;
    border-radius: 4px;
    font-family: monospace;
    font-size: 0.9rem;
    word-break: break-all;
    color: #0f172a;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.guid-result:hover {
    background-color: #f1f5f9;
}

.copy-button {
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    opacity: 0.6;
}

.guid-result:hover .copy-button {
    opacity: 1;
}

.copy-button:hover {
    background-color: #e2e8f0;
    color: #0369a1;
}

.snackbar {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #0369a1;
    color: white;
    padding: 12px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
    font-family: var(--font-body);
    font-size: 0.9rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.action-button {
    background-color: #0369a1;
    color: white;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    align-self: flex-start;
}

.action-button:hover {
    background-color: #0284c7;
}

.translation-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.translation-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

textarea {
    width: 100%;
    min-height: 150px;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 1rem;
    resize: vertical;
}

.full-width {
    grid-column: 1 / -1;
}

.upload-container {
    margin-bottom: 1rem;
}

.upload-area {
    border: 2px dashed #e2e8f0;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    color: #64748b;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.transcription-result textarea {
    min-height: 200px;
}

footer {
    padding: 1.5rem;
    text-align: center;
    color: #64748b;
    font-size: 0.9rem;
    border-top: 1px solid #e2e8f0;
    background-color: #f8fafc;
}

@media (max-width: 768px) {
    .tools-grid {
        grid-template-columns: 1fr;
    }

    .translation-container {
        flex-direction: column;
    }

    .tabs button {
        padding: 0.75rem 0.5rem;
        font-size: 0.9rem;
    }
}

.guid-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
}

.notification-message {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #03a12d;
    color: white;
    border-radius: 4px;
    font-size: 0.9rem;
    text-align: center;
    animation: fadeIn 0.3s, fadeOut 0.3s 4.7s;
}

.version-selector {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.version-selector label {
    font-size: 0.9rem;
    color: #64748b;
    font-weight: 500;
}

.version-selector select {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 0.9rem;
}

.swap-button {
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    min-width: 32px;
    min-height: 32px;
}

.swap-button .material-symbols-outlined {
    font-size: 20px;
}

.swap-button:hover {
    background-color: #e2e8f0;
    color: #0369a1;
    transform: scale(1.05);
}

.swap-button:active {
    transform: scale(0.95);
    background-color: #cbd5e1;
}

/* Ad Banners */
.ad-banner {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 160px;
    height: 600px;
    background-color: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.ad-banner-left {
    left: 10px;
}

.ad-banner-right {
    right: 10px;
}

.ad-label {
    width: 100%;
    text-align: center;
    padding: 4px 0;
    font-size: 0.7rem;
    color: #64748b;
    background-color: #e2e8f0;
    border-radius: 4px 4px 0 0;
}

/* Hide ads on smaller screens */
@media (max-width: 1280px) {
    .ad-banner {
        display: none;
    }
}